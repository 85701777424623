import React, { useState } from "react";
import Nav from "../component/common/Nav";
import Footer from "../component/common/FooterFixed";

import styled from "@emotion/styled";
import theme from "../style/theme";
import { Box, Button, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";

function WebEmotion() {
  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Nav />
      <EmotionConiainer>
        <EmotionForm>
          <h3>서비스 준비 중 입니다.</h3>
        </EmotionForm>
      </EmotionConiainer>
      <Footer />
    </ThemeProvider>
  );
}

const EmotionConiainer = styled(Container)`
  width: 1180px;
  padding: 150px 0 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #1b2654;
    margin-bottom: 50px;
  }
  @media ${() => theme.device.mobile} {
    width: 100%;
    h1 {
      font-size: 25px;
    }
  }
`;

const EmotionForm = styled(Container)`
  margin-top: 5px;
  width: 880px;
  h3 {
    font-size: 25px;
    color: #1573ff;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
  }
  @media ${() => theme.device.mobile} {
    width: 350px;
    h3 {
      font-size: 20px;
    }
  }
`;

export default WebEmotion;
