import React, { useState, useRef, useEffect } from "react";
import theme from "../style/theme";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Container,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import Nav from "../component/common/Nav";
import Footer from "../component/common/Footer";

function CustomerSuppert() {
  const form = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [appname, setAppName] = useState("(주)사운더스트리");
  const [support, setSupport] = useState("");
  const Swal = require("sweetalert2");

  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });
  const openModal = () => {
    if (isChecked === true) {
      setIsChecked(false);
    } else {
      setIsOpen(true);
    }
  };

  const closeModal = (bool) => {
    setIsChecked((prevChecked) => (bool === true ? true : false)); // 수정된 부분
    setIsOpen(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (isChecked === false || email.length === 0 || support.length === 0) {
      Swal.fire({
        title: "빠진 항목이 있어요!",
        html: "1. 개인정보 제공 동의 확인<br> 2. email<br> 3. 문의 내역",
        icon: "warning",
        confirmButtonText: "확인",
        confirmButtonColor: "#1573ff",
      });
    } else {
      if (form.current.appname.value.trim() === "") {
        form.current.appname.value = "(주)사운더스트리";
      }
      emailjs
        .sendForm(
          "soundustry-service-mail",
          "template_fzqg02z",
          form.current,
          "SF3TwCWMibP4w7W0h"
        )
        .then(
          (result) => {
            //console.log(result.text);
          },
          (error) => {
            //console.log(error.text);
          }
        );
      Swal.fire({
        title: "문의 감사합니다.",
        html: "빠른 시일 내에 답변드리겠습니다.",
        icon: "success",
        confirmButtonText: "확인",
        confirmButtonColor: "#1573ff",
      });
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Nav />
      <CustomerSuppertContainer>
        <h1>무엇을 도와드릴까요?</h1>
        <CustomerSupportForm>
          <div className="checkbox">
            <FormControlLabel
              control={<Checkbox />}
              label="개인 정보 제공 동의"
              labelPlacement="start"
              checked={isChecked}
              onChange={openModal} // 수정된 부분
            />
          </div>
          {isOpen && (
            <ModalOverlay>
              <ModalContent>
                <h1>개인 정보 제공 동의</h1>
                <h2>
                  안녕하세요. 문의 내역은 이메일을 통해 답변을 드리고 있기
                  때문에 개인정보 동의가 필요합니다.
                </h2>
                <div className="sebuP">
                  <p>
                    1. 수집하는 개인정보 항목
                    <br />- 이메일
                  </p>
                  <p>
                    2. 개인정보의 수집 및 이용 목적
                    <br />- 문의 내역에 대한 답변을 보내드리기 위해 이용합니다.
                  </p>
                  <p>
                    3. 개인정보의 보유 및 이용기간
                    <br />- 문의 내역에 대한 답변이 완료되면 즉시 파기합니다.
                  </p>
                </div>
                <div className="button">
                  <StyledButton
                    variant="outlined"
                    onClick={() => closeModal(true)}>
                    동의합니다.
                  </StyledButton>
                  <StyledButton
                    variant="outlined"
                    onClick={() => closeModal(false)}>
                    동의하지 않습니다.
                  </StyledButton>
                </div>
              </ModalContent>
            </ModalOverlay>
          )}
          <form ref={form} onSubmit={sendEmail}>
            <SupportTop>
              <div className="wrap-email">
                <h3>이메일</h3>
                <CustomInputEmail
                  type="text"
                  name="email"
                  placeholder="어플문의 : 어플 계정 이메일"
                  onChange={(e) => setEmail(e.target.value)}></CustomInputEmail>
              </div>
              <div className="wrap-app">
                <h3>사용하신 어플</h3>
                <CustomInputEmail
                  type="text"
                  name="appname"
                  placeholder="(주)사운더스트리"
                  value={appname}
                  onChange={(e) => {
                    setAppName(e.target.value);
                  }}></CustomInputEmail>
              </div>
            </SupportTop>
            <SupportBottom>
              <div className="wrap-support">
                <h3>문의 내역</h3>
                <CustomTextareaSupport
                  type="text"
                  name="support"
                  onChange={(e) =>
                    setSupport(e.target.value)
                  }></CustomTextareaSupport>
              </div>
            </SupportBottom>
            <div className="submit-btn">
              <StyledButton isOpen={isOpen} type="submit" variant="outlined">
                문의 남기기
              </StyledButton>
            </div>
          </form>
        </CustomerSupportForm>
      </CustomerSuppertContainer>
      <Footer />
    </ThemeProvider>
  );
}

export default CustomerSuppert;

const CustomerSuppertContainer = styled(Container)`
  width: 1180px;
  height: 1296px;
  padding: 150px 0 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #1b2654;
    margin-bottom: 50px;
  }
  @media ${() => theme.device.mobile} {
    width: 100%;
    h1 {
      font-size: 25px;
    }
  }
`;

const CustomerSupportForm = styled(Box)`
  border-radius: 4px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 880px;
  h3 {
    font-size: 20px;
    color: #1573ff;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 5px;
  }
  .wrap-support {
    padding-left: 85px;
  }
  .checkbox {
    color: grey;
    padding-top: 20px;
    padding-left: 80px;
  }
  .submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  @media ${() => theme.device.mobile} {
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-bottom: 200px;
    h1 {
      font-size: 25px;
    }
  }
  @media ${() => theme.device.mobile6} {
    width: 220px;
    height: 700px;
    align-items: center;
    justify-content: center;
  }
`;

const SupportTop = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  .wrap-name {
    padding-left: 85px;
  }
  .wrap-email {
    padding-left: 85px;
    padding-right: 80px;
  }
  .wrap-app {
    padding-left: 10px;
    padding-right: 80px;
  }
  @media ${() => theme.device.mobile} {
    flex-direction: column;
    .wrap-app {
      margin-top: 10px;
      padding-left: 85px;
      padding-right: 80px;
    }
    ::placeholder {
      font-size: 8px;
    }
  }
`;
const CustomInputEmail = styled.input`
  border: none;
  background-color: #f2f2f2;
  border-radius: 5px;
  width: 300px;
  height: 40px;
  font-weight: bold;
  font-size: 18px;
  ::placeholder {
    font-size: 12px;
  }
  @media ${() => theme.device.mobile} {
    width: 500px;
    font-size: 12px;
  }
  @media ${() => theme.device.mobile2} {
    width: 400px;
  }
  @media ${() => theme.device.mobile3} {
    width: 200px;
  }
  @media ${() => theme.device.mobile6} {
    width: 150px;
    height: 20px;
    font-size: 15px;
  }
`;

const SupportBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
`;
const CustomTextareaSupport = styled.textarea`
  border: none;
  background-color: #f2f2f2;
  border-radius: 5px;
  width: 700px;
  height: 250px;
  font-weight: bold;
  font-size: 18px;
  resize: none;
  outline: none;
  padding-left: 10px;
  @media ${() => theme.device.mobile} {
    width: 500px;
  }
  @media ${() => theme.device.mobile2} {
    width: 400px;
  }
  @media ${() => theme.device.mobile3} {
    width: 200px;
  }
  @media ${() => theme.device.mobile6} {
    width: 150px;
    height: 300px;
    font-size: 15px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${() => theme.device.mobile6} {
    width: 280px;
  }
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  h1 {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #1b2654;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .sebuP {
    border: 1px solid;
    padding: 10px;
  }
  @media ${() => theme.device.mobile} {
    width: 80%;
    h1 {
      font-size: 18px;
    }
  }
`;

const StyledButton = styled(Button)`
  min-width: 250px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #1573ff;
  border-color: #1573ff;
  box-shadow: none;
  margin: 10px;
  &:hover {
    color: #fff;
    background-color: #1573ff;
  }
  visibility: ${({ isOpen }) => {
    return isOpen ? "hidden" : "visible";
  }};
  @media ${() => theme.device.mobile} {
    min-width: 230px;
    font-size: 15px;
  }
  @media ${() => theme.device.mobile3} {
    min-width: 110px;
    font-size: 15px;
  }
  @media ${() => theme.device.mobile6} {
    min-width: 110px;
    font-size: 15px;
  }
`;
