import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import theme from "../style/theme";
import { createTheme, ThemeProvider } from "@mui/material";
import {
  Box,
  Button,
  Container,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

function DetailPage() {
  const { id } = useParams();
  const [item, setItem] = useState(null);

  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });

  useEffect(() => {
    axios
      //.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/notice/sebu?id=${encodeURIComponent(id)}`)
      .get(`/api/notice/sebu?id=${encodeURIComponent(id)}`)
      .then((res) => {
        setItem(JSON.parse(res.data));
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  return (
    <NoticeSebuContainer>
      <h1>공지사항</h1>
      <NoticeForm>
        {item ? (
          <>
            <h3 class="title">제목 : {item.title}</h3>
            <p class="registration">{item.registration}</p>
            <hr></hr>
            <p class="content">{item.content}</p>
          </>
        ) : (
          <h1>데이터가 없어요</h1>
        )}
      </NoticeForm>
    </NoticeSebuContainer>
  );
}

const NoticeSebuContainer = styled(Container)`
  width: 1180px;
  padding: 150px 0 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #1b2654;
    margin-bottom: 50px;
  }
  @media ${() => theme.device.mobile} {
    width: 100%;
    h1 {
      font-size: 25px;
    }
  }
`;

const NoticeForm = styled(Container)`
  border-radius: 4px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  width: 880px;
  h3 {
    font-size: 20px;
    color: #1573ff;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 5px;
  }
  .title {
    margin-top: 30px;
    text-align: left; /* 제목을 왼쪽으로 정렬 */
  }
  .registration {
    text-align: right; /* 단락을 오른쪽으로 정렬 */
  }
  .content {
    margin-top: 30px;
    white-space: pre-wrap;
    margin-bottom: 30px;
  }
  @media ${() => theme.device.mobile} {
    width: 90%;
    margin-bottom: 200px;
    h1 {
      font-size: 25px;
    }
  }
  @media ${() => theme.device.mobile6} {
    width: 220px;
    height: 700px;
    align-items: center;
    justify-content: center;
  }
`;

const NoticeTitle = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  .wrap-name {
    padding-left: 85px;
  }
  .wrap-email {
    padding-left: 85px;
    padding-right: 80px;
  }
  .wrap-app {
    padding-left: 10px;
    padding-right: 80px;
  }
  @media ${() => theme.device.mobile} {
    flex-direction: column;
    ::placeholder {
      font-size: 8px;
    }
  }
`;

export default DetailPage;
