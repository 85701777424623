// 오디오 녹음 부분으로 녹음 및 녹음 정지 , 음성 재생 제공, 녹음 종료시 바로 유창성 평가
import React, { useState } from "react";
import theme from "../style/theme";
import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import dataStore from "../stores/DataStore";

const AudioRecord = ({ text, index, evaluation, onResult }) => {
  const [analyser, setAnalyser] = useState();
  const [stream, setStream] = useState();
  const [media, setMedia] = useState();
  const [onRec, setOnRec] = useState(true);
  const [source, setSource] = useState();
  const [audioUrl, setAudioUrl] = useState();

  const onRecAudio = () => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    // 자바스크립트를 통해 음원의 진행상태에 직접접근에 사용된다.
    const analyser = audioCtx.createScriptProcessor(0, 1, 1);
    setAnalyser(analyser);

    function makeSound(stream) {
      // 내 컴퓨터의 마이크나 다른 소스를 통해 발생한 오디오 스트림의 정보를 보여준다.
      const source = audioCtx.createMediaStreamSource(stream);
      setSource(source);
      source.connect(analyser);
      analyser.connect(audioCtx.destination);
    }
    // 마이크 사용 권한 획득
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start();
        setStream(stream);
        setMedia(mediaRecorder);
        makeSound(stream);

        analyser.onaudioprocess = function (e) {
          // (20초) 지나면 자동으로 음성 저장 및 녹음 중지
          if (e.playbackTime > 20) {
            stream.getAudioTracks().forEach(function (track) {
              track.stop();
            });
            mediaRecorder.stop();
            // 메서드가 호출 된 노드 연결 해제
            analyser.disconnect();
            audioCtx.createMediaStreamSource(stream).disconnect();

            mediaRecorder.ondataavailable = function (e) {
              setAudioUrl(e.data);
              setOnRec(true);
            };
          } else {
            setOnRec(false);
          }
        };
      });
  };

  const offRecAudio = () => {
    // dataavailable 이벤트로 Blob 데이터에 대한 응답을 받을 수 있음
    media.ondataavailable = async function (e) {
      await setAudioUrl(e.data);
      if (evaluation === 0) {
        await sendAudio(e.data);
      } else if (evaluation === 1) {
        await sendAudioEval(e.data);
      } else if (evaluation === 2) {
        await sendAudioSori(e.data);
      }
      setOnRec(true);
    };

    // 모든 트랙에서 stop()을 호출해 오디오 스트림을 정지
    stream.getAudioTracks().forEach(function (track) {
      track.stop();
    });

    // 미디어 캡처 중지
    media.stop();

    // 메서드가 호출 된 노드 연결 해제
    analyser.disconnect();
    source.disconnect();

    if (audioUrl) {
      URL.createObjectURL(audioUrl); // 출력된 링크에서 녹음된 오디오 확인 가능
    }
  };

  const play = () => {
    const audio = new Audio(URL.createObjectURL(audioUrl));
    audio.loop = false;
    audio.volume = 1;
    audio.play();
  };

  // 통신 - 파일 통신은 멀티 파트로 한다.
  // 샘플링 16000hz 샘플링 필요
  const sendAudio = async (audioData) => {
    // File 생성자를 사용해 파일로 변환
    const corscript = text;
    const formData = new FormData();
    const sound = new File([audioData], "soundBlob.mp3", {
      lastModified: new Date().getTime(),
      type: "audio",
    });
    formData.append("blob", sound);
    formData.append("script", corscript);
    //fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/test/eval`, {
    fetch(`/api/test/eval`, {
      method: "POST",
      body: formData,
      headers: {
        //'Content-Type': 'multipart/form-data',
        //'Content-Type': 'application/x-www-form-urlencoded',
        //'Content-Type': 'audio/mpeg',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        dataStore.addJsonData(index, result); // 받은 JSON 데이터를 스토어에 추가
      });
    // 컴포넌트 내에서 `sendAudio` 함수를 호출할 때 `dataStore`를 전달합니다.
  };

  //유창성 평가용
  const sendAudioEval = async (audioData) => {
    // File 생성자를 사용해 파일로 변환
    const corscript = text;
    const formData = new FormData();
    const sound = new File([audioData], "soundBlob.mp3", {
      lastModified: new Date().getTime(),
      type: "audio",
    });
    formData.append("blob", sound);
    formData.append("script", corscript);

    //fetch(`${process.env.REACT_APP_API_URL}/api/test/eval`, {
    //fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/draw/eval`, {
    fetch(`/api/draw/eval`, {
      method: "POST",
      body: formData,
      headers: {
        //'Content-Type': 'multipart/form-data',
        //'Content-Type': 'application/x-www-form-urlencoded',
        //'Content-Type': 'audio/mpeg',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        onResult(result, audioData);
      });
    // 컴포넌트 내에서 `sendAudio` 함수를 호출할 때 `dataStore`를 전달합니다.
  };
  //소리 테스트용
  const sendAudioSori = async (audioData) => {
    // File 생성자를 사용해 파일로 변환
    const corscript = text;
    const formData = new FormData();
    const sound = new File([audioData], "soundBlob.mp3", {
      lastModified: new Date().getTime(),
      type: "audio",
    });
    formData.append("blob", sound);
    formData.append("script", corscript);

    //fetch(`${process.env.REACT_APP_API_URL}/api/test/eval`, {
    //fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/sori/test`, {
    fetch(`/api/sori/test`, {
      method: "POST",
      body: formData,
      headers: {
        //'Content-Type': 'multipart/form-data',
        //'Content-Type': 'application/x-www-form-urlencoded',
        //'Content-Type': 'audio/mpeg',
      },
    })
      .then((response) => {
        if (!response.ok) {
        } else {
          return response.json();
        }
      })
      .then((result) => {
        onResult(result, audioData);
      })
      .catch((error) => {
        alert("음성이 너무 작습니다. 다시 녹음해주세요.");
      });
    // 컴포넌트 내에서 `sendAudio` 함수를 호출할 때 `dataStore`를 전달합니다.
  };

  return (
    <>
      <StyledBtn
        variant="outlined"
        onClick={onRec ? onRecAudio : offRecAudio}
        startIcon={<KeyboardVoiceIcon />}>
        {" "}
        {onRec ? "녹음" : "녹음 중지"}
      </StyledBtn>
      {
        //<StyledBtn variant="outlined" onClick={play} disabled={!onRec || !audioUrl} style={{marginRight:10}}>음성 재생</StyledBtn>
      }
    </>
  );
};

const StyledBtn = styled(Button)`
  width: fit-content;
  padding: 5px 20px;
  color: #1573ff;
  border-color: #1573ff;
  font-weight: bold;
  @media ${() => theme.device.mobile} {
    width: 100%;
  }
`;

export default AudioRecord;
