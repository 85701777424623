import React, { useState } from "react";
import styled from "@emotion/styled";
import Nav from "../component/common/Nav";
import Footer from "../component/common/Footer";
import { Button, Container, Box, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RecordWeb from "../component/RecordWeb";
import { createTheme, ThemeProvider } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import ReactHtmlParser from "html-react-parser";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import axios from "axios";
import { donut, bar } from "../component/Graph";
import theme from "../style/theme";
function Record() {
  const [inputText, setInputText] = useState("");
  const [evaluation, setEval] = useState(false);
  const [audioUrl, setAudioUrl] = useState(false);
  const [active, setActive] = useState(false);
  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });
  const onResult = (result, audioUrl) => {
    setActive(true);
    setEval(result);
    setAudioUrl(audioUrl);
  };

  const sound = () => {
    // 유저 사운드
    const file = audioUrl;
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audio.play();
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Nav />
        <WebEvalConiainer>
          <h1>영어 유창성 평가</h1>
          <WebPresentationForm>
            <div className="wpWrap">
              <h3>발화문장을 입력해주세요.</h3>
              <h4>발화 문장을 입력 후 녹음 버튼을 눌러주세요.</h4>
              <textarea
                value={inputText}
                onChange={(e) => {
                  setInputText(e.target.value);
                }}
                col={5}
                maxLength={200}
                placeholder="최대 200자까지 입력 가능합니다."></textarea>
              <div className="center">
                {inputText.length > 0 && (
                  <RecordWeb
                    text={inputText}
                    index={0}
                    evaluation={1}
                    onResult={onResult}>
                    녹음
                  </RecordWeb>
                )}
              </div>
              {active && (
                <EvalForm>
                  <h3>문장별 평가 Report</h3>
                  <div className="result-box">
                    <div className="script-sebu1">
                      {"정답스크립트: " + evaluation.script}
                    </div>
                    <div className="btn-script2">
                      <div className="script-sebu2">
                        <div>
                          {"유저스크립트: "}
                          {ReactHtmlParser(evaluation.compare)}
                        </div>
                      </div>
                      <SoundButton
                        variant="outlined"
                        startIcon={<VolumeUpIcon />}
                        onClick={() => sound()}>
                        내 음성
                      </SoundButton>
                    </div>
                    <Graph>
                      <div className="acustic">
                        <ReactApexChart
                          options={donut([evaluation.acustic]).options}
                          series={donut([evaluation.acustic]).series}
                          type="radialBar"
                          className="radialBar"
                          height={250}
                        />
                      </div>
                      <div id="acusticTitle">유창성점수</div>
                      <div id="acusticScore">
                        {Math.round(evaluation.acustic)}점
                      </div>
                      <div className="score">
                        <ReactApexChart
                          options={
                            bar([
                              evaluation.rateScore,
                              evaluation.evalData[3] * 20,
                              evaluation.evalData[4] * 20,
                            ]).options
                          }
                          series={
                            bar([
                              evaluation.rateScore,
                              evaluation.evalData[3] * 20,
                              evaluation.evalData[4] * 20,
                            ]).series
                          }
                          type="bar"
                          className="bar"
                          height={220}
                          width={450}
                        />
                      </div>
                      <div id="rate">발음 정확도</div>
                      <div id="stress">강세 & 리듬</div>
                      <div id="speed">발성속도</div>
                    </Graph>
                    <GraphMobile>
                      <div className="acustic">
                        <PronTitle>유창성 점수</PronTitle>
                        <div className="score">
                          {Math.round(evaluation.acustic)}점
                        </div>
                      </div>
                      <div className="acustic">
                        <PronTitle>발음 정확도</PronTitle>
                        <div className="score">
                          {Math.round(evaluation.rateScore)}점
                        </div>
                      </div>
                      <div className="acustic">
                        <PronTitle>발성속도</PronTitle>
                        <div className="score">
                          {evaluation.evalData[4] * 20}점
                        </div>
                      </div>
                      <div className="acustic">
                        <PronTitle>강세 & 리듬</PronTitle>
                        <div className="score">
                          {evaluation.evalData[4] * 20}점
                        </div>
                      </div>
                    </GraphMobile>
                    <div className="threeScore">
                      <PronTitle>Pronunciation</PronTitle>
                      <div className="evalThree">
                        <div className="three">
                          {ReactHtmlParser(evaluation.wordScore)}.
                        </div>
                      </div>
                      <PronTitle>Pause</PronTitle>
                      <div className="evalThree">
                        <div className="three">
                          {ReactHtmlParser(evaluation.longPause)}.
                        </div>
                      </div>
                      <PronTitle>Stress</PronTitle>
                      <div className="stress">
                        <div>{ReactHtmlParser(evaluation.stress)}.</div>
                      </div>
                    </div>
                  </div>
                </EvalForm>
              )}
            </div>
          </WebPresentationForm>
        </WebEvalConiainer>

        <Footer />
      </ThemeProvider>
    </>
  );
}
const WebEvalConiainer = styled(Container)`
  width: 1180px;
  padding: 150px 0 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #1b2654;
    margin-bottom: 50px;
  }

  @media ${() => theme.device.mobile} {
    width: 100%;
    h1 {
      font-size: 25px;
    }
  }
`;

const WebPresentationForm = styled(Box)`
  border-radius: 4px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  background-color: #fff;

  width: 880px;
  h3 {
    font-size: 25px;
    color: #1573ff;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
  }
  h4 {
    font-size: 15px;
    color: #1573ff;
    opacity: 70%;
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 20px;
    text-align: left;
  }
  textarea {
    width: 770px !important;
    height: 120px !important;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 30px;
    resize: none;
    outline: none;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wpWrap {
    padding: 50px;
  }

  @media ${() => theme.device.mobile} {
    width: 90%;
    textarea {
      width: 100% !important;
      height: 250px !important;
    }
    h3 {
      font-size: 20px;
    }
    .resultBox {
      width: 100%;
    }
    .wpWrap {
      padding: 30px;
    }
    threeScore {
      display: none;
    }
  }
`;

const SoundButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin-left: 20px;
  margin-top: 20px;
  color: #1573ff;
  border-color: #1573ff;
  font-weight: bold;
  @media ${() => theme.device.mobile} {
    width: content-fit;
    margin-left: 0;
  }
`;

const EvalForm = styled(Box)`
  margin-top: 20px;
  .result-box {
    padding: 10px;
    border: 1px solid;
    font-size: 20px;
  }
  .script-sebu2 {
    display: flex;
    margin-top: 20px;
  }
  .btn-script2 {
    display: flex;
  }
  @media ${() => theme.device.mobile} {
    width: 100%;
    .result-box {
      font-size: 18px;
    }
    .script-sebu2 {
    }
    .btn-script2 {
      display: flex;
      flex-direction: column;
    }
  }
`;
const PronTitle = styled(Box)`
  width: fit-content;
  color: #1b2654;
  font-size: 25px;
  border-radius: 20px;
  padding: 5px 10px;
  margin-bottom: 5px;
  color: #000000;
  border: 1px solid #000000;
  line-height: 1;
  text-align: left;
  margin-top: 5px;
  margin-left: 10px;
  @media ${() => theme.device.mobile} {
    margin-left: 0px;
    margin-top: 20px;
    font-size: 20px;
  }
`;

const Graph = styled.div`
  @media ${() => theme.device.mobile} {
    display: none;
  }
  position: relative;
  width: 100%;
  height: 250px; /* 예시를 위한 높이 설정 */
  .acustic {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%; /* 너비 조정 */
  }
  #acusticTitle {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 80px;
    margin-left: 70px;
  }
  #acusticScore {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 110px;
    margin-left: 100px;
  }
  .score {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%; /* 너비 조정 */
  }
  #rate {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 45px;
    margin-left: 230px;
  }
  #stress {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 90px;
    margin-left: 230px;
  }
  #speed {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 140px;
    margin-left: 240px;
  }
  .threeScoreBox {
    position: relative;
  }
`;
const GraphMobile = styled.div`
  display: none;
  @media ${() => theme.device.mobile} {
    display: block;
  }
  .acustic {
    display: flex;
  }
  .acustic {
    display: flex;
  }
  .score {
    margin-top: 30px;
    margin-left: 20px;
  }
`;
export default Record;
