import React, { useState } from "react";
import styled from "@emotion/styled";
import { Container, Box, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material";
import theme from "../../style/theme";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";

const preventDefault = (event) => event.preventDefault();

export default function Nav() {
  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActive(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActive(false);
  };
  const scrollToElement = () => {
    const serviceSection = document.getElementById("service");
    if (serviceSection) {
      serviceSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
    setActive2(true);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
    setActive2(false);
  };

  const [open3, setOpen3] = React.useState(false);

  const handleClick3 = () => {
    setOpen3(!open3);
  };

  const [open4, setOpen4] = React.useState(false);

  const handleClick4 = () => {
    setOpen4(!open4);
    setOpen5(false);
  };

  const [open5, setOpen5] = React.useState(false);

  const handleClick5 = () => {
    setOpen5(!open5);
    setOpen4(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <PC>
        <NavWrap>
          <div className="navBar">
            <NavContainer>
              <StyledLink href="/" underline="none">
                <div className="logoImg">
                  <img src="../img/logo-b.svg" width="100%" />
                </div>
              </StyledLink>
              <MenuList>
                <MenuWrap>
                  <MenuBtn
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    style={{ color: active ? "#1573FF" : "#1B2654" }}>
                    Home
                  </MenuBtn>
                  <StyledMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}>
                    {" "}
                    <StyledLink href="/#companyintro1" underline="none">
                      <StyledMenuItem onClick={handleClose}>
                        회사소개
                      </StyledMenuItem>
                    </StyledLink>
                    <StyledLink href="/#service1" underline="none">
                      <StyledMenuItem onClick={handleClose}>
                        서비스소개
                      </StyledMenuItem>
                    </StyledLink>
                    <StyledLink href="/#history" underline="none">
                      <StyledMenuItem onClick={handleClose}>
                        연혁
                      </StyledMenuItem>
                    </StyledLink>
                    <StyledLink href="/#news" underline="none">
                      <StyledMenuItem onClick={handleClose}>
                        뉴스
                      </StyledMenuItem>
                    </StyledLink>
                    <StyledLink href="/#footer" underline="none">
                      <StyledMenuItem onClick={handleClose}>
                        컨택
                      </StyledMenuItem>
                    </StyledLink>
                  </StyledMenu>
                </MenuWrap>
                <MenuWrap>
                  <MenuBtn
                    id="basic-button"
                    aria-controls={open2 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleClick2}
                    style={{ color: active2 ? "#1573FF" : "#1B2654" }}>
                    서비스
                  </MenuBtn>
                  <StyledMenu
                    id="basic-menu"
                    anchorEl={anchorEl2}
                    open={open2}
                    onClose={handleClose2}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}>
                    <StyledLink
                      href="/Service/SpeechRecognition&Synthesis"
                      underline="none">
                      <StyledMenuItem onClick={handleClose2}>
                        음성인식 / 합성
                      </StyledMenuItem>
                    </StyledLink>
                    <StyledLink href="/Service/EN&Evaluation" underline="none">
                      <StyledMenuItem onClick={handleClose2}>
                        영어 유창성평가
                      </StyledMenuItem>
                    </StyledLink>
                    <StyledLink href="/service/EN_SORI" underline="none">
                      <StyledMenuItem onClick={handleClose2}>
                        영어 소리테스트
                      </StyledMenuItem>
                    </StyledLink>
                    <StyledLink
                      href="/Service/SpeakerRecognition&Separation"
                      underline="none">
                      <StyledMenuItem onClick={handleClose2}>
                        화자인식 / 분리
                      </StyledMenuItem>
                    </StyledLink>
                    <StyledLink
                      href="/Service/EmotionRecognition"
                      underline="none">
                      <StyledMenuItem onClick={handleClose2}>
                        감정인식
                      </StyledMenuItem>
                    </StyledLink>
                  </StyledMenu>
                </MenuWrap>
                <MenuWrap>
                  <MenuBtn href="/Support">고객지원</MenuBtn>
                </MenuWrap>
                <MenuWrap>
                  <MenuBtn href="/Notice">공지사항</MenuBtn>
                </MenuWrap>
                <MenuWrap>
                  <MenuBtn
                    href="https://www.saramin.co.kr/zf_user/company-info/view?csn=V3gvV3cxVlhKenNqSzU1TEFXUlFSQT09&popup_yn=y"
                    target="_blank">
                    채용
                  </MenuBtn>
                </MenuWrap>
                {/*<MenuBtnSolid  href="/">
                                    로그인
                                    </MenuBtnSolid>*/}
              </MenuList>
            </NavContainer>
          </div>
        </NavWrap>
      </PC>
      <Mobile>
        <NavWrap>
          <div className="navBar">
            <StyledList
              sx={{ width: "100%", bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader">
              <ListItemButton onClick={handleClick3}>
                <StyledLink href="/" underline="none">
                  <div className="logoImg">
                    <img src="../img/logo-b.svg" width="100%" />
                  </div>
                </StyledLink>
                <MenuIcon />
              </ListItemButton>
              <Collapse in={open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ mt: 2 }}>
                  <ListItemButton onClick={handleClick4} sx={{ p: 1 }}>
                    <StyledListItemText primary="Home" />
                    {open4 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open4} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <StyledLink href="/#companyintro1" underline="none">
                        <ListItemButton sx={{ pl: 3 }}>
                          <ListItemText primary="회사소개" />
                        </ListItemButton>
                      </StyledLink>
                      <StyledLink href="/#service1" underline="none">
                        <ListItemButton sx={{ pl: 3 }}>
                          <ListItemText primary="서비스소개" />
                        </ListItemButton>
                      </StyledLink>
                      <StyledLink href="/#history" underline="none">
                        <ListItemButton sx={{ pl: 3 }}>
                          <ListItemText primary="연혁" />
                        </ListItemButton>
                      </StyledLink>
                      <StyledLink href="/#news" underline="none">
                        <ListItemButton sx={{ pl: 3 }}>
                          <ListItemText primary="뉴스" />
                        </ListItemButton>
                      </StyledLink>
                      <StyledLink href="/#footer" underline="none">
                        <ListItemButton sx={{ pl: 3 }}>
                          <ListItemText primary="컨택" />
                        </ListItemButton>
                      </StyledLink>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={handleClick5} sx={{ p: 1 }}>
                    <StyledListItemText primary="서비스" />
                    {open5 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open5} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <StyledLink
                        href="/Service/SpeechRecognition&Synthesis"
                        underline="none">
                        <ListItemButton sx={{ pl: 3 }}>
                          <ListItemText primary="음성인식 / 합성" />
                        </ListItemButton>
                      </StyledLink>
                      <StyledLink
                        href="/Service/EN&Evaluation"
                        underline="none">
                        <ListItemButton sx={{ pl: 3 }}>
                          <ListItemText primary="영어 유창성평가" />
                        </ListItemButton>
                      </StyledLink>
                      <StyledLink href="/service/EN_SORI" underline="none">
                        <ListItemButton sx={{ pl: 3 }}>
                          <ListItemText primary="영어 소리테스트" />
                        </ListItemButton>
                      </StyledLink>
                      <StyledLink
                        href="/Service/SpeakerRecognition&Separation"
                        underline="none">
                        <ListItemButton sx={{ pl: 3 }}>
                          <ListItemText primary="화자인식 / 분리" />
                        </ListItemButton>
                      </StyledLink>
                      <StyledLink
                        href="/Service/EmotionRecognition"
                        underline="none">
                        <ListItemButton sx={{ pl: 3 }}>
                          <ListItemText primary="감정인식" />
                        </ListItemButton>
                      </StyledLink>
                    </List>
                  </Collapse>
                  <StyledLink href="/Support" underline="none">
                    <ListItemButton sx={{ p: 1 }}>
                      <StyledListItemText primary="고객지원" />
                    </ListItemButton>
                  </StyledLink>
                  <StyledLink href="/Notice" underline="none">
                    <ListItemButton sx={{ p: 1 }}>
                      <StyledListItemText primary="공지사항" />
                    </ListItemButton>
                  </StyledLink>
                  <StyledLink
                    href="https://www.saramin.co.kr/zf_user/company-info/view?csn=V3gvV3cxVlhKenNqSzU1TEFXUlFSQT09&popup_yn=y"
                    underline="none"
                    target="_blank">
                    <ListItemButton sx={{ p: 1 }}>
                      <StyledListItemText primary="채용" />
                    </ListItemButton>
                  </StyledLink>
                  {/*
                                    <StyledLink href="#" underline="none">
                                        <ListItemButton sx={{ p : 1}}>
                                            <StyledListItemText primary="로그인" />
                                        </ListItemButton>
                                    </StyledLink>
                                */}
                </List>
              </Collapse>
            </StyledList>
          </div>
        </NavWrap>
      </Mobile>
    </ThemeProvider>
  );
}

const PC = styled(Box)`
  @media ${() => theme.device.tablet} {
    display: none;
  }
`;

const Mobile = styled(Box)`
  display: none;
  @media ${() => theme.device.mobile} {
    display: block;
  }
`;

const NavWrap = styled(Box)`
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  .navBar {
    background-color: #fff;
    box-shadow: 0 0 10px #d9d9d9;
    padding: 15px 0;
    .logoImg {
      width: 180px;
    }
  }
  @media ${() => theme.device.tablet} {
    .navBar {
      padding: 5px 20px;
      .logoImg {
        width: 150px;
      }
    }
  }
  @media ${() => theme.device.mobile6} {
    width: 280px;
    .navBar {
      padding: 5px 20px;
      .logoImg {
        width: 150px;
      }
    }
  }
`;

const NavContainer = styled(Container)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MenuList = styled(Box)`
  display: flex;
  align-items: center;
`;

const MenuWrap = styled(Box)`
  margin-right: 30px;
`;

const MenuBtn = styled(Button)`
  color: #1b2654;
  font-size: 16px;
  border-radius: 20px;
  padding: 5px 13px;
  &:hover {
    color: #1573ff;
  }
`;

const MenuBtnSolid = styled(Button)`
  color: #1b2654;
  font-size: 16px;
  border-radius: 20px;
  padding: 5px 10px;
  color: #1573ff;
  border: 1px solid #1573ff;
  line-height: 1;
  &:hover {
    color: #fff;
    background-color: #1573ff;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    min-width: 120px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 16px;
`;

const StyledList = styled(List)`
  .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
`;

const StyledListItemText = styled(ListItemText)`
  span {
    font-weight: bold;
    color: #1b2654;
  }
`;

const StyledLink = styled(Link)`
  color: #1b2654;
`;
