import React, { useState } from "react";
import Nav from "../component/common/Nav";
import Footer from "../component/common/FooterFixed";

import styled from "@emotion/styled";
import theme from "../style/theme";
import { Box, Button, Container } from "@mui/material";
import {createTheme,ThemeProvider} from '@mui/material';

function WebSpeaker(){
    const theme = createTheme({
        typography:{
            fontFamily : "Pretendard"
        }
    })
    return(
        <ThemeProvider theme={theme}>
        <Nav />
        <SpeakerConiainer>
            <SpeakerForm>
                <h3>서비스 준비 중 입니다.</h3>
            </SpeakerForm>
        </SpeakerConiainer>
        <Footer />
        </ThemeProvider>
    )
}

const SpeakerConiainer = styled(Container)`
    width: 1180px;
    padding: 150px 0 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1{
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        color: #1B2654;
        margin-bottom: 50px;
    }
    @media ${() => theme.device.mobile} {
        width: 100%;
        h1{
            font-size: 25px;
        }
    }
`;

const SpeakerForm = styled(Container)`
    margin-top: 5px;
    width: 880px;
    h3{
        font-size: 25px;
        color: #1573FF;
        font-weight: bold;
        margin-bottom: 30px;
        text-align: center;
    }
    @media ${() => theme.device.mobile} {
        width: 350px;
        h3{
            font-size: 20px;
        }
    }
`
export default WebSpeaker;