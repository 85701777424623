import React, { useState } from "react";
import styled from "@emotion/styled";
import { Container, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import theme from "../../style/theme";

export default function Footer() {
  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <FooterWrap id="footer">
          <FooterContainer>
            <div className="logo">
              <img src="../img/logo-w.svg"></img>
            </div>
            <div className="footer-text">
              <h4>(주)사운더스트리</h4>
              <h3>
                <strong>메일</strong> : soundustry.inc@gmail.com
                <br />
                <strong>주소</strong> : 34129 대전광역시 유성구 가정로 218
                한국전자통신연구원 11동 231호
                <br />
                <strong>전화번호</strong> : 042.860.1043
                <br />
                <strong>FAX 번호</strong> : 0504.280.1043
              </h3>
            </div>
          </FooterContainer>
        </FooterWrap>
      </ThemeProvider>
    </>
  );
}

const FooterWrap = styled(Box)`
  background-color: #1b2654;
  padding: 100px 0;
  bottom: 0;
  left: 0;
  width: 100%;
  position: fixed;
  @media ${() => theme.device.mobile} {
    padding: 50px 0;
  }
`;

const FooterContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  .footer-text {
    margin-left: 100px;
    h4 {
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 16px;
      line-height: 180%;
      color: #fff;
      opacity: 0.8;
      strong {
        font-weight: bold;
      }
    }
  }
  @media ${() => theme.device.mobile} {
    flex-direction: column;
    width: 100%;
    height: 80px;
    img {
      display: none;
    }
    .footer-text {
      margin: 30px 0 0 0;
      h3 {
        font-size: 14px;
      }
    }
  }
`;
