import React, { useState } from "react";
import { Button, Container } from "@mui/material";
import styled from "@emotion/styled";
import ScaleLoader from "react-spinners/ScaleLoader";
import { createTheme, ThemeProvider } from "@mui/material";
import theme from "../style/theme";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

function TTS({ script }) {
  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });
  const [spinner, SetSpinner] = useState(false);
  const [audio, setAudio] = useState(null);
  const sound = () => {
    audio.loop = false;
    audio.volume = 1;
    audio.play();
  };
  const ttsSend = () => {
    setAudio(null);
    SetSpinner(true);
    const formData = new FormData();
    formData.append("script", script);
    //fetch(`${process.env.REACT_APP_API_URL}/api/test/TTS`, {
    fetch(`/api/test/TTS`, {
      method: "POST",
      body: formData,
      headers: {
        //'Content-Type': 'multipart/form-data',
        //'Content-Type': 'application/x-www-form-urlencoded',
        //'Content-Type': 'audio/mpeg',
      },
    })
      .then((response) => response.blob())
      .then((result) => {
        SetSpinner(false);
        // 음성 데이터를 사용하여 작업 수행
        // 예: HTML 오디오 요소로 재생
        const audioUrl = URL.createObjectURL(result); // Blob URL 생성
        const audioElement = new Audio(audioUrl);
        setAudio(audioElement);
      });
  };

  return (
    <>
      <Container_>
        <ButtonContainer>
          <TTSBtn
            variant="outlined"
            type="submit"
            onClick={ttsSend}
            disabled={spinner}>
            {spinner ? <ScaleLoader color="#1573FF" /> : "입력완료"}
          </TTSBtn>
        </ButtonContainer>
        {audio && (
          <AudioContainer>
            <SoundButton
              variant="outlined"
              startIcon={<VolumeUpIcon />}
              onClick={() => sound()}>
              내 음성
            </SoundButton>
          </AudioContainer>
        )}
      </Container_>
    </>
  );
}

const Container_ = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const AudioContainer = styled.div`
  align-self: flex-end;
`;

const TTSBtn = styled(Button)`
  min-width: fit-content;
  border-radius: 50px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 5px 50px;
  color: #1573ff;
  border-color: #1573ff;
  box-shadow: none;
  &:hover {
    color: #fff;
    background-color: #1573ff;
  }
`;
const SoundButton = styled(Button)`
  width: 100px;
  height: 40px;
  margin-left: 20px;
  margin-top: 20px;
  color: #1573ff;
  border-color: #1573ff;
  font-weight: bold;
  @media ${() => theme.device.mobile} {
    width: content-fit;
    margin-left: 0;
  }
`;
export default TTS;
