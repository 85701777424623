import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Nav from "../component/common/Nav";
import { Box, Button, Container } from "@mui/material";
// import styled from "@emotion/styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircleIcon from "@mui/icons-material/Circle";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Footer from "../component/common/Footer";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { createTheme, ThemeProvider } from "@mui/material";
import PaginationMui from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import theme from "../style/theme";
import axios from "axios";
import ReactHtmlParser from "html-react-parser";
import PopupCom from "../component/popup";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

SwiperCore.use([Pagination, Navigation, Autoplay]);

function Main() {
  const [newsData, SetNews] = useState([]); // 뉴스 데이터 세팅
  const [popup, SetPopUp] = useState("");
  const [popupOpen, SetPopupOpen] = useState(false);
  useEffect(() => {
    axios
      //.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/News`)
      .get(`/api/News`)
      .then((res) => {
        const news_2022 = res.data["2022"];
        const news_2023 = res.data["2023"];
        const popData = res.data["popup"];
        const news = [news_2022, news_2023];
        SetNews(news);
        SetPopUp(popData);
        if (popData != "") {
          SetPopupOpen(true);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);
  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });

  const [view, setView] = React.useState("10");

  const handleChange = (event) => {
    setView(event.target.value);
  };

  const [newsSwiper, setNewsSwiper] = useState(null);

  /* 버튼 상태 처리 */
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(true);
  const handleClick = (event) => {
    setActive(!active);
    setActive2(!active2);
  };

  const handleClick2 = (event) => {
    setActive2(!active2);
    setActive(!active);
  };

  const [newsView, setNewsView] = useState(false);

  const handleClickView = (event) => {
    setNewsView(!newsView);
  };

  const itemsPerPage = 3; // 뉴스 리스트 아이템 개수
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태값

  // 현재 페이지에 해당하는 뉴스 아이템들 반환
  const getCurrentPageItems = (index) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return newsData.length > 0
      ? newsData[index].slice(startIndex, endIndex)
      : [];
  };
  // 페이지 변경 시 호출되는 핸들러 함수
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // 현재 페이지에 해당하는 뉴스 아이템들
  const current2022 = getCurrentPageItems(0);
  const current2023 = getCurrentPageItems(1);

  return (
    <>
      <ThemeProvider id="root" theme={theme}>
        <Popupdiv>{popupOpen ? <PopupCom image={popup} /> : <></>}</Popupdiv>
        <Nav />
        <Background>
          <div className="textWrap">
            <h1>
              음성 기반 인공지능 기술 <br />
              <strong>NO.1 기업</strong>
            </h1>
            <h3>
              사운더스트리는 <strong>인공지능 기반</strong>의 <br />
              <strong>음성 서비스 및 솔루션</strong>을 선도하고 있습니다.
            </h3>
          </div>
          <div className="downIcon">
            <KeyboardArrowDownIcon />
          </div>
        </Background>
        <CompanyIntro id="companyintro1">
          <div className="introBox">
            <img className="introImg" src="../img/tech.jpg" />
            <div className="introBox-text">
              <h2>누구보다 정확하고 누구에게나 맞춤형 솔루션</h2>
              <p>
                음성 기반 인공지능 솔루션 선두기업 (주)사운더스트리는
                한국전자통신연구원 창업 기업으로 R&D 노하우와 기술력으로 고객
                맞춤형 솔루션을 제공하고 있습니다. <br />
                (주)사운더스트리는 국내 최고의{" "}
                <strong>한국인 발화 영어 음성인식</strong>, <br />
                국내 최초 <strong>자유발화에 대한 튜터링 솔루션</strong>으로
                고객의 맞춤형 <br />
                서비스를 만들어 가고 있습니다.
                <br />
              </p>
            </div>
          </div>
          <div className="introBox d-reverse">
            <img className="introImg" src="../img/main-img2.png" />
            <div className="introBox-text">
              <h2>소리에 가치를 만드는 인공지능 전문기업</h2>
              <p>
                <strong>"Unleashing the Potential of Sound!!"</strong>
                <br />
                (주)사운더스트리는 소리에 가치를 만들어 나가고 있습니다.
                <br />
                (주)사운더스트리 인공지능 기술은 소리에 진정한 가치를 <br />
                창출하고 사람과 조화하는 사회를 만들어가고자 합니다.
              </p>
            </div>
          </div>
        </CompanyIntro>
        <Service id="service1">
          <div className="serviceBackground" id="SB1">
            <ServiceContainer>
              <div className="serviceWrap">
                <ul className="dotWrap">
                  <li className="dot select">
                    <a href="#service1">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot">
                    <a href="#service2">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot">
                    <a href="#service3">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot">
                    <a href="#service4">
                      <CircleIcon />
                    </a>
                  </li>
                </ul>
                <div className="service-text">
                  <h4>서비스 01</h4>
                  <h2>
                    음성인식 / 합성
                    <br />
                  </h2>
                  <p className="serviceBox">
                    <br />
                    한국전자통신연구원의 음성 인식기술과 맞춤형 적응학습
                    솔루션으로
                    <br /> 더 정확한 음성인식 서비스를 제공합니다.
                    <br />
                  </p>
                  <SLBtn
                    variant="contained"
                    href="/Service/SpeechRecognition&Synthesis">
                    바로가기
                  </SLBtn>
                </div>
              </div>
            </ServiceContainer>
          </div>
        </Service>
        <Service id="service2">
          <div className="serviceBackground" id="SB2">
            <ServiceContainer>
              <div className="serviceWrap">
                <ul className="dotWrap">
                  <li className="dot">
                    <a href="#service1">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot select">
                    <a href="#service2">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot">
                    <a href="#service3">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot">
                    <a href="#service4">
                      <CircleIcon />
                    </a>
                  </li>
                </ul>
                <div className="service-text">
                  <h4>서비스 02</h4>
                  <h2>화자인식 / 분리</h2>
                  <p className="serviceBox">
                    노이즈가 있는 환경에서도 정확한 화자를 분류하는 솔루션을
                    제공합니다.
                  </p>
                  <SLBtn
                    variant="contained"
                    href="/Service/SpeakerRecognition&Separation">
                    바로가기
                  </SLBtn>
                </div>
              </div>
            </ServiceContainer>
          </div>
        </Service>
        <Service id="service3">
          <div className="serviceBackground" id="SB3">
            <ServiceContainer>
              <div className="serviceWrap">
                <ul className="dotWrap">
                  <li className="dot">
                    <a href="#service1">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot">
                    <a href="#service2">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot select">
                    <a href="#service3">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot">
                    <a href="#service4">
                      <CircleIcon />
                    </a>
                  </li>
                </ul>
                <div className="service-text">
                  <h4>서비스 03</h4>
                  <h2>감정인식</h2>
                  <p className="serviceBox">
                    문장과 음성을 기반으로 감정을 분류하는 솔루션을 제공합니다.
                  </p>
                  <SLBtn variant="contained" href="/Service/EmotionRecognition">
                    바로가기
                  </SLBtn>
                </div>
              </div>
            </ServiceContainer>
          </div>
        </Service>
        <Service id="service4">
          <div className="serviceBackground" id="SB4">
            <ServiceContainer>
              <div className="serviceWrap">
                <ul className="dotWrap">
                  <li className="dot">
                    <a href="#service1">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot">
                    <a href="#service2">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot">
                    <a href="#service3">
                      <CircleIcon />
                    </a>
                  </li>
                  <li className="dot select">
                    <a href="#service4">
                      <CircleIcon />
                    </a>
                  </li>
                </ul>
                <div className="service-text">
                  <h4>서비스 04</h4>
                  <h2>영어 유창성평가</h2>
                  <p className="serviceBox">
                    어떤 문장이든 고객의 말하기에 대한 피드백을 제공합니다.
                  </p>
                  <SLBtn variant="contained" href="/Service/EN&Evaluation">
                    바로가기
                  </SLBtn>
                </div>
              </div>
            </ServiceContainer>
          </div>
        </Service>
        <CompanyIntro id="history">
          <div className="historyWrap">
            <h1>연혁</h1>
            <div className="history">
              <ul>
                <li>
                  <CircleIcon />
                  <h3>2022.04</h3>
                  <div>
                    <h4>
                      한국 전자 통신연구원 창업 기업 (주)사운더스트리 설립
                    </h4>
                    <h4>청년 창업 사관학교 입교</h4>
                  </div>
                </li>
                <li>
                  <CircleIcon />
                  <h3>2022.06</h3>
                  <div>
                    <h4>한국형 아이코어 선정</h4>
                    <h4>과학 창업 아이디어 공모전 대상 수상</h4>
                  </div>
                </li>
                <li>
                  <CircleIcon />
                  <h3>2022.07</h3>
                  <div>
                    <h4>SEED 투자 유치 (블루포인트파트너스)</h4>
                    <h4>Dream Venture Star with DAEKYO 선정 </h4>
                  </div>
                </li>
                <li>
                  <CircleIcon />
                  <h3>2022.10</h3>
                  <div>
                    <h4>팁스 과제 선정</h4>
                  </div>
                </li>
                <li className="select-dot">
                  <CircleIcon />
                  <h3 className="select">현재</h3>
                  <div>
                    <h4>청년 창업 우수 졸업 (중소벤처기업진흥공단 이사장상)</h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="newsWrap" id="news">
            <h1>뉴스</h1>
            <div className="news">
              <div className="option">
                <div className="year">
                  <RSBtn
                    variant="contained"
                    style={{
                      color: active ? "#1B2654" : "#fff",
                      backgroundColor: active ? "#fff" : "#1573FF",
                    }}
                    onClick={handleClick}>
                    2022
                  </RSBtn>
                  <RSBtn
                    variant="contained"
                    style={{
                      color: active2 ? "#1B2654" : "#fff",
                      backgroundColor: active2 ? "#fff" : "#1573FF",
                    }}
                    onClick={handleClick2}>
                    2023
                  </RSBtn>
                </div>
                <div className="select">
                  <StyledFormControl sx={{ m: 1, minWidth: 120 }}>
                    <StyledSelect
                      defaultValue={10}
                      value={view}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}>
                      <MenuItem value={10} onClick={handleClickView}>
                        그림으로 확인
                      </MenuItem>
                      <MenuItem value={20} onClick={handleClickView}>
                        목록으로 확인
                      </MenuItem>
                    </StyledSelect>
                  </StyledFormControl>
                </div>
              </div>

              {!newsView ? (
                <NewsAlbum>
                  <NavigationBtn>
                    <div
                      className="prev-news"
                      onClick={() => {
                        newsSwiper.slidePrev();
                      }}>
                      <ArrowLeftIcon />
                    </div>
                    <div
                      className="next-news"
                      onClick={() => {
                        newsSwiper.slideNext();
                      }}>
                      <ArrowRightIcon />
                    </div>
                  </NavigationBtn>
                  <Swiper
                    slidesPerView={2}
                    spaceBetween={20}
                    pagination={{ clickable: true }}
                    onSwiper={setNewsSwiper}
                    loop={true}
                    breakpoints={{
                      0: {
                        slidesOffsetBefore: 0,
                        slidesPerView: 1,
                        spaceBetween: 20,
                        centeredSlides: false,
                      },
                      800: {
                        slidesOffsetBefore: 0,
                        slidesPerView: 1,
                        spaceBetween: 20,
                        centeredSlides: false,
                      },
                      1200: {
                        slidesOffsetBefore: 0,
                        slidesPerView: 2,
                        spaceBetween: 10,
                        centeredSlides: false,
                      },
                    }}>
                    {newsData.length > 0 &&
                      (active ? newsData[1] : newsData[0]).map(
                        (news, index) => (
                          <SwiperSlide className="newsAlbum" key={index}>
                            <div>
                              <a
                                className="underline"
                                href={news.href}
                                target="_blank">
                                <div className="newsImg">
                                  <img src={news.src} width="100%" alt="news" />
                                </div>
                                <div className="newsText">
                                  <h2>{ReactHtmlParser(news.title)}</h2>
                                  <p>{news.content}</p>
                                  <p>{news.date}</p>
                                </div>
                              </a>
                            </div>
                          </SwiperSlide>
                        )
                      )}
                  </Swiper>
                </NewsAlbum>
              ) : (
                <NewsList>
                  <ul className="newsListWrap">
                    {(active ? current2023 : current2022).map((news, index) => (
                      <a className="underline" href={news.href} target="_blank">
                        <li key={index}>
                          <h3>{news.number}</h3>
                          <h2>{ReactHtmlParser(news.title)}</h2>
                          <p>{news.content}</p>
                          <p>{news.date}</p>
                        </li>
                      </a>
                    ))}
                  </ul>
                  <div className="dp-flex">
                    <PaginationMui
                      count={Math.ceil(
                        (active ? newsData[1] : newsData[0]).length /
                          itemsPerPage
                      )}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                    />
                  </div>
                </NewsList>
              )}
            </div>
          </div>
        </CompanyIntro>
        <Footer />
      </ThemeProvider>
    </>
  );
}

const Background = styled(Box)`
  background-image: url(../img/mainBackground.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .textWrap {
    padding-top: 100px;
    padding-right: 20px;
    h1 {
      color: #fff;
      text-align: center;
      font-size: 45px;
      font-weight: 300;
      line-height: 130%;
      margin-bottom: 30px;
      strong {
        font-size: 50px;
        font-weight: bold;
      }
    }
    h3 {
      color: #fff;
      opacity: 0.7;
      text-align: center;
      font-size: 20px;
      line-height: 140%;
      strong {
        font-weight: bold;
      }
    }
  }

  .downIcon {
    position: absolute;
    bottom: 30px;
    svg {
      color: #fff;
      font-size: 60px;
    }
  }

  @media ${() => theme.device.mobile3} {
    .textWrap {
      padding: 0 20px;
      padding-top: 50px;
      h1 {
        font-size: 25px;
        strong {
          font-size: 30px;
        }
      }
      h3 {
        font: 1px;
      }
    }
  }
`;

const CompanyIntro = styled(Container)`
  width: 1180px;
  padding: 200px 0;
  .introBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    img.introImg {
      width: 480px;
      height: 280px;
    }
    .introBox-text {
      padding: 0 80px;
      h2 {
        font-size: 28px;
        font-weight: bold;
        color: #1573ff;
        margin-bottom: 30px;
      }
      p {
        font-size: 20px;
        line-height: 140%;
        color: rgba(27, 38, 84, 0.7);
        strong {
          font-weight: bold;
        }
      }
    }
  }
  .introBox:first-of-type {
    margin-bottom: 150px;
  }
  .d-reverse {
    flex-direction: row-reverse;
  }
  h1 {
    font-size: 50px;
    color: #1b2654;
    font-weight: bold;
    margin-right: 100px;
    width: 150px;
    min-width: 150px;
  }

  .historyWrap {
    display: flex;
    align-items: flex-start;

    .history {
      border-left: 3px solid #e8eaf2;
      position: relative;
      width: 600px;
      height: 410px;
      ul {
        position: absolute;
        top: -10px;
        left: -9px;
      }
      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 50px;

        svg {
          font-size: 15px;
          color: #e8eaf2;
          padding-top: 3px;
        }
        h3 {
          margin: 0 50px 0 25px;
          font-size: 20px;
          color: #1b2654;
          font-weight: 500;
          width: 100px;
        }
        h4 {
          font-size: 18px;
          color: #1b2654;
        }
        h4:first-of-type {
          margin-bottom: 20px;
        }
      }
      .select-dot {
        svg {
          color: #1573ff !important;
        }
      }
      .select {
        font-weight: bold;
        color: #1573ff;
      }
      li:last-child {
        margin: 0;
        h4 {
          margin: 0;
        }
      }
    }
  }
  .newsWrap {
    padding-top: 200px;
    display: flex;
    align-items: flex-start;
    .news {
      width: 100%;
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media ${() => theme.device.mobile} {
    width: 100%;
    padding: 0 20px;
    .introBox {
      flex-direction: column-reverse;
      .introBox-text {
        padding: 0 0 50px 0;
        width: 100%;
        h2 {
          font-size: 19px;
        }
        p {
          font-size: 14px;
        }
        img.introImg {
          width: 100%;
        }
      }
    }
    .introBox:last-child {
      img {
        margin-bottom: 100px;
      }
    }
    h1 {
      font-size: 30px;
      margin-bottom: 50px;
    }
    .introBox:first-of-type {
      margin-bottom: 100px;
      margin-top: 100px;
    }
    .historyWrap {
      flex-direction: column;
      padding: 100px 0;
      .history {
        width: 100%;
        height: 100%;
        border: none;
        ul {
          position: static;
        }
        li {
          flex-direction: column;
          margin-bottom: 30px;
          h3 {
            margin: 20px 0;
          }
        }
      }
    }
    .newsWrap {
      padding: 0 0 100px 0;
      flex-direction: column;
    }
  }

  @media ${() => theme.device.mobile2} {
    .introBox {
      img.introImg {
        width: 100%;
      }
    }
    .introBox:last-child {
      img {
        margin-bottom: 100px;
      }
    }
  }
`;

const Service = styled(Box)`
  .serviceBackground {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
  }
  #SB1 {
    background-image: url(../img/serviceBackground1.png);
  }
  #SB2 {
    background-image: url(../img/serviceBackground2.png);
  }
  #SB3 {
    background-image: url(../img/serviceBackground3.png);
  }
  #SB4 {
    background-image: url(../img/serviceBackground4.png);
  }

  .dotWrap {
    padding-top: 70px;
    .dot {
      margin-bottom: 10px;
      svg {
        font-size: 15px;
        color: #fff;
      }
    }
    .select {
      svg {
        color: #1573ff;
      }
    }
    .dot:last-child {
      margin: 0;
    }
  }

  .service-text {
    padding-top: 70px;
    margin-left: 80px;
    h4 {
      font-size: 25px;
      color: #fff;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 50px;
      line-height: 130%;
      color: #fff;
      font-weight: bold;
      margin-bottom: 25px;
    }
    p.serviceBox {
      color: white;
      font-size: 18px;
      margin-bottom: 40px;
      line-height: 140%;
    }
  }

  @media ${() => theme.device.mobile} {
    .dotWrap {
      display: flex;
      width: 100px;
      justify-content: space-between;
    }
    .service-text {
      padding: 0;
      margin: 0;
      margin-top: 100px;

      h4 {
        font-size: 16px;
      }
      h2 {
        font-size: 30px;
      }
    }
  }
`;

const ServiceContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .serviceWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
  }

  @media ${() => theme.device.mobile} {
    .serviceWrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 20px;
    }
  }
`;

const NewsAlbum = styled(Box)`
  width: 930px;
  margin-top: 30px;
  position: relative;
  .swiper-pagination {
    text-align: left;
    span {
      width: 12px;
      height: 12px;
    }
  }
  .swiper-horizontal {
    height: 580px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .newsAlbum {
    height: 540px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
    &:hover {
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
    }
    .underline {
      text-decoration: none;
      color: black;
    }
    .newsImg {
      height: 270px;
      img {
        height: 100%;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
    .newsText {
      padding: 30px;
      h2 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        line-height: 130%;
      }
      p {
        font-size: 16px;
        line-height: 140%;
        color: rgba(27, 38, 84, 0.5);
      }
      p:last-child {
        margin-top: 20px;
      }
    }
  }
  @media ${() => theme.device.mobile} {
    width: 100%;
    .swiper-horizontal {
      width: 100%;
      height: 520px;
    }
    .newsAlbum {
      height: 470px;
      .newsImg {
        height: 200px;
      }
      .newsText {
        h2 {
          font-size: 18px;
        }
        h2 br {
          display: none;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
`;

const NewsList = styled(Box)`
  .newsListWrap {
    padding: 30px 0;
    .underline {
      text-decoration: none;
      color: black;
    }
    li {
      padding: 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      cursor: pointer;
      background-color: #fff;
      h3 {
        font-size: 14px;
        font-weight: bold;
        color: #1573ff;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        line-height: 140%;
        color: rgba(27, 38, 84, 0.5);
        width: 870px;
      }
      p:last-child {
        margin-top: 20px;
      }
    }
    li:last-child {
      margin-bottom: 0;
    }
    li:hover {
      background-color: #e8eaf2;
    }
  }
  .css-wjh20t-MuiPagination-ul li:first-of-type button {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .css-wjh20t-MuiPagination-ul li:last-child button {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .css-1cnejrw-MuiButtonBase-root-MuiPaginationItem-root {
    border: none;
  }
  .css-1cnejrw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #1573ff;
    color: #fff;
    border: none;
  }
  .dp-flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media ${() => theme.device.mobile} {
    .newsListWrap {
      margin-bottom: 30px;
      li {
        h2 {
          line-height: 130%;
        }
        p {
          width: 100%;
        }
      }
    }
  }
`;

const SLBtn = styled(Button)`
  border-radius: 4px;
  background-color: #1573ff;
  font-size: 20px !important;
  color: #fff;
  font-weight: bold !important;
  padding: 10px 50px !important;
`;

const RSBtn = styled(Button)`
  border-radius: 50px !important;
  box-shadow: none !important;
  padding: 3px 13px !important;
  font-size: 16px;
  &:first-of-type {
    margin-right: 20px;
  }
`;

const StyledFormControl = styled(FormControl)`
  .MuiSelect-select {
    padding: 5px 10px;
    font-size: 14px;
  }
`;
const StyledSelect = styled(Select)`
  li {
    font-size: 14px !important;
    text-align: center;
  }
`;

const NavigationBtn = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  svg {
    font-size: 20px;
  }
  .prev-news,
  .next-news {
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 5px;
  }
  svg:hover {
    color: #1573ff;
  }
  .prev-news {
    margin-right: 1rem;
  }
`;

const Popupdiv = styled.div`
  width: 300px;
  height: 200px;
`;
export default Main;
