import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
//import WebPresentation from "./pages/WebPresentation";
import STTS from "./pages/WebSTTS";
import Speaker from "./pages/WebSpeaker";
import Emotion from "./pages/WebEmotion";
import Support from "./pages/CustomerSupport";
import GlobalStyle from "./style/GlobalStyle";
import WebEval from "./pages/WebEval";
import SoriEn from "./pages/WebSoriEn";
import Notice from "./pages/Notice";
import NoticeSebu from "./pages/NoticeSebu";
function App() {
  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/service/EN&Evaluation" element={<WebEval />}></Route>
        <Route path="/service/EN_SORI" element={<SoriEn />}></Route>
        {/*잠시 올려둘 소리 테스트*/}
        <Route
          path="/service/SpeechRecognition&Synthesis"
          element={<STTS />}></Route>
        <Route
          path="/Service/SpeakerRecognition&Separation"
          element={<Speaker />}></Route>
        <Route path="/Service/EmotionRecognition" element={<Emotion />}></Route>
        <Route path="/Support" element={<Support />}></Route>
        <Route path="/Notice" element={<Notice />}></Route>
        <Route path="/Notice/:id" element={<NoticeSebu />}></Route>
      </Routes>
    </>
  );
}

export default App;
