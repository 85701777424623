// 색깔
const colorSet = (value) => {
  if (value >= 65.0) {
    return "#00A300";
  } else if (value >= 40.0 && value < 64.9) {
    return "#FFA500";
  } else {
    return "#BC2916";
  }
};

// 도넛 그래프
export const donut = (size) => ({
  series: [size],
  options: {
    chart: {
      type: "radialBar",
      fontFamily: "Cafe24Ssurround",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "60%",
        },
        dataLabels: {
          value: {
            show: false,
          },
        },
      },
    },
    colors: [
      function ({ value, serisIndex, w }) {
        return colorSet(value);
      },
    ],
    labels: [""],
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
  },
});

// 바그래프
export const bar = (size) => ({
  series: [
    {
      data: size,
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 200,
      fontFamily: "Cafe24Ssurround",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        colors: {
          ranges: [
            {
              from: 0,
              to: 40,
              color: "#BC2916", // Set color for the range 0 to 40
            },
            {
              from: 40,
              to: 70,
              color: "#FFA500", // Set color for the range 40 to 70
            },
            {
              from: 70,
              to: 100,
              color: "#00A300", // Set color for the range 70 to 100
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["", "", ""],
      min: 0,
      max: 100,
      labels: {
        style: {
          fontSize: "15px",
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    annotations: {
      xaxis: [
        {
          x: 40,
          strokeDashArray: 5,
          opacity: 0.5,
          borderColor: "rgba(0, 0, 0, 0.4)",
        },
        {
          x: 70,
          strokeDashArray: 5,
          opacity: 0.5,
          borderColor: "rgba(0, 0, 0, 0.4)",
        },
      ],
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
  },
});
