import { makeObservable, observable, action } from 'mobx';

class DataStore {
  jsonData = [];

  constructor() {
    makeObservable(this, {
      jsonData: observable,
      addJsonData: action,
      clear: action
    });
  }

    addJsonData(index,json) {
    if (index >= 0 && index < this.jsonData.length) {
        this.jsonData[index] = json; // 특정 인덱스에 데이터 수정
      } else {
        this.jsonData.push(json); // 배열 끝에 데이터 추가
      }
    }
    clear(){
        this.jsonData = [];
    }

}

const dataStore = new DataStore();
export default dataStore;
