import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import theme from "../style/theme";

// 모달 오버레이 스타일 정의
const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// 모달 콘텐츠 스타일 정의
const StyledContent = styled.div`
  background: white;
  width: 600px;
  height: 600px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  @media ${() => theme.device.mobile2} {
    margin-top: 5%;
    width: 50%;
    height: 25%;
  }
`;

// 버튼 스타일 정의
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border: none;
  background-color: #ffffff;
  color: white;
  font-size: 15px;
  border-radius: 4px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
`;

function PopupCom({ image }) {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: "transparent", // 스타일 오버레이 투명하게 설정
        },
        content: {
          position: "relative",
          inset: 0,
          border: "none",
          background: "none",
          padding: 0,
          overflow: "none",
        },
      }}
      overlayClassName="StyledOverlay"
      className="StyledContent">
      <StyledOverlay>
        <StyledContent>
          <img
            src={image}
            alt="No Image"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
          <CloseButton onClick={closeModal}>X</CloseButton>
        </StyledContent>
      </StyledOverlay>
    </Modal>
  );
}

export default PopupCom;
