import React, { useState, useEffect } from "react";
import Nav from "../component/common/Nav";
import Footer from "../component/common/FooterFixed";
import axios from "axios";
import styled from "@emotion/styled";
import theme from "../style/theme";
import { Box, Button, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import PaginationMui from "@mui/material/Pagination";
import ReactHtmlParser from "html-react-parser";
import { Link } from "react-router-dom";

function WebEmotion() {
  const [notice, SetNotice] = useState([]);
  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });
  useEffect(() => {
    axios
      //.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/notice`)
      .get(`/api/notice`)
      .then((res) => {
        const data = JSON.parse(res.data); // 문자열을 배열로 변환
        SetNotice(data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Nav />
      <NoticeConiainer>
        <h1>공지사항</h1>
        <NewsList>
          <ul className="newsListWrap">
            {notice.length > 0 &&
              notice.map((news) => (
                <li key={news._id}>
                  <Link to={`/Notice/${news._id}`} className="underline">
                    <h2>{ReactHtmlParser(news.title)}</h2>
                    <p>{news.content}</p>
                    <p>{news.registration}</p>
                  </Link>
                </li>
              ))}
          </ul>
        </NewsList>
      </NoticeConiainer>
      <Footer />
    </ThemeProvider>
  );
}

const NoticeConiainer = styled(Container)`
  width: 1180px;
  padding: 150px 0 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #1b2654;
    margin-bottom: 50px;
  }
  @media ${() => theme.device.mobile} {
    width: 100%;
    h1 {
      font-size: 25px;
    }
  }
`;

const NoticeForm = styled(Container)`
  margin-top: 5px;
  width: 880px;
  h3 {
    font-size: 25px;
    color: #1573ff;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
  }
  @media ${() => theme.device.mobile} {
    width: 350px;
    h3 {
      font-size: 20px;
    }
  }
`;

const NewsList = styled(Box)`
  .newsListWrap {
    padding: 30px 0;
    .underline {
      text-decoration: none;
      color: black;
    }
    li {
      padding: 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      cursor: pointer;
      background-color: #fff;
      h3 {
        font-size: 14px;
        font-weight: bold;
        color: #1573ff;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        line-height: 140%;
        color: rgba(27, 38, 84, 0.5);
        width: 870px;
      }
      p:last-child {
        margin-top: 20px;
      }
    }
    li:last-child {
      margin-bottom: 0;
    }
    li:hover {
      background-color: #e8eaf2;
    }
  }
  .css-wjh20t-MuiPagination-ul li:first-of-type button {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .css-wjh20t-MuiPagination-ul li:last-child button {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .css-1cnejrw-MuiButtonBase-root-MuiPaginationItem-root {
    border: none;
  }
  .css-1cnejrw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #1573ff;
    color: #fff;
    border: none;
  }
  .dp-flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media ${() => theme.device.mobile} {
    .newsListWrap {
      margin-bottom: 30px;
      li {
        h2 {
          line-height: 130%;
        }
        p {
          width: 100%;
        }
      }
    }
  }
`;

export default WebEmotion;
