import React, { useState, useEffect } from "react";
import Nav from "../component/common/Nav";
import Footer from "../component/common/Footer";
import RecordWebSTT from "../component/RecordWebSTT";
import TTS from "../component/TTS";
import FileUpload from "../component/FileUploadSTT";
import Streaming from "../component/RecordWebStreaming";
import { createTheme, ThemeProvider } from "@mui/material";
import { Box, Button, Container } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styled from "@emotion/styled";
import theme from "../style/theme";
import { ElevatorSharp } from "@mui/icons-material";

function STTS() {
  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });
  const [lan, setLan] = React.useState("EN");
  /* 버튼 상태 처리  on off 라 로직을 변경해야될 것으로 보임.*/
  const [active, setActive] = useState(false);
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(true);

  const handleClick = (event) => {
    setActive(false);
    setActive1(true);
    setActive2(true);
  };

  const handleClick1 = (event) => {
    setActive(true);
    setActive1(false);
    setActive2(true);
  };

  const handleClick2 = (event) => {
    setActive(true);
    setActive1(true);
    setActive2(false);
  };

  const [LanView, setLanView] = useState(false);
  const handleClickView = (event) => {
    setLanView(!LanView);
    setInputText("");
  };
  const handleChange = (event) => {
    setLan(event.target.value);
  };

  const [inputText, setInputText] = useState("");
  const handleChangeTextArea = (event) => {
    //정규표현식 제한
    const inputValue = event.target.value;
    setInputText(inputValue);
  };
  const [STTresult_EN, setSTTResult_EN] = useState("");
  const [STTresult_KR, setSTTResult_KR] = useState("");
  const [StreamingResult_EN, setStreamingResult_EN] = useState("");
  const [StreamingResult_KR, setStreamingResult_KR] = useState("");
  const handleSTTResult = (result) => {
    if (lan == "EN") {
      if (result == "") {
        setSTTResult_EN("다시 녹음해주세요.");
      } else {
        setSTTResult_EN(result);
      }
    } else if (lan == "KR") {
      if (result == "") {
        setSTTResult_KR("다시 녹음해주세요.");
      } else {
        setSTTResult_KR(result);
      }
    }
  };
  const handleStreamingResult = (result) => {
    if (lan == "EN") {
      if (result == "") {
        setStreamingResult_EN("다시 녹음해주세요.");
      } else {
        setStreamingResult_EN(result);
      }
    } else if (lan == "KR") {
      if (result == "") {
        setStreamingResult_KR("다시 녹음해주세요.");
      } else {
        setStreamingResult_KR(result);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Nav />
      <STTSConiainer>
        <div>
          <h1>음성 인식 & 음성 합성</h1>
          <div
            className="btnWrap"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <div>
              <RSBtn
                variant="outlined"
                style={{
                  color: active ? "#1B2654" : "#fff",
                  backgroundColor: active ? "#fff" : "#1573FF",
                }}
                onClick={handleClick}>
                음성 인식
              </RSBtn>
              <RSBtn
                variant="outlined"
                style={{
                  color: active1 ? "#1B2654" : "#fff",
                  backgroundColor: active1 ? "#fff" : "#1573FF",
                }}
                onClick={handleClick1}>
                실시간 인식
              </RSBtn>
              <RSBtn
                variant="outlined"
                style={{
                  color: active2 ? "#1B2654" : "#fff",
                  backgroundColor: active2 ? "#fff" : "#1573FF",
                }}
                onClick={handleClick2}>
                음성 합성
              </RSBtn>
            </div>
            <div>
              <StyledFormControl sx={{ m: 1, minWidth: 120 }}>
                <StyledSelect
                  defaultValue={10}
                  value={lan}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}>
                  <MenuItem value={"EN"} onClick={handleClickView}>
                    영어
                  </MenuItem>
                  <MenuItem value={"KR"} onClick={handleClickView}>
                    한국어
                  </MenuItem>
                </StyledSelect>
              </StyledFormControl>
            </div>
          </div>
          {lan === "EN" && active === false && (
            <STTSForm>
              <div className="wpWrap">
                <h3>영어 문장을 녹음해주세요.</h3>
                <div className="btn-relation">
                  <RecordWebSTT lan={lan} onResult={handleSTTResult} />
                  <FileUpload lan={lan} onResult={handleSTTResult}></FileUpload>
                </div>
                {/* 녹음 코드 변경하기. stt 부분만 할 수 있도록 */}
                {STTresult_EN !== "" ? <h4>{STTresult_EN}</h4> : <></>}
                {/* 음성인식 결과 출력 */}
              </div>
            </STTSForm>
          )}
          {lan === "EN" && active1 === false && (
            <STTSForm>
              <div className="wpWrap">
                <h3>실시간 음성인식을 제공합니다.</h3>
                <Streaming lan={lan} onResult={handleStreamingResult} />
                {/* 녹음 코드 변경하기. stt 부분만 할 수 있도록 */}
                {StreamingResult_EN !== "" ? (
                  <h4>{StreamingResult_EN}</h4>
                ) : (
                  <></>
                )}
                {/* 음성인식 결과 출력 */}
              </div>
            </STTSForm>
          )}
          {lan === "EN" && active2 === false && (
            <STTSForm>
              <div className="wpWrap">
                <h3>영어 문장을 입력해주세요.</h3>
                <textarea
                  value={inputText}
                  onChange={handleChangeTextArea}
                  col={10}
                  maxLength={100}
                  placeholder="최대 100글자까지 입력할 수 있습니다."></textarea>
                <div className="center">
                  {inputText !== "" && <TTS script={inputText} />}
                </div>
              </div>
            </STTSForm>
          )}
          {lan !== "EN" &&
            active === false && ( // 한국어
              <STTSForm>
                <div className="wpWrap">
                  <h3>한국어 문장을 녹음해주세요.</h3>
                  <RecordWebSTT lan={lan} onResult={handleSTTResult} />
                  <FileUpload lan={lan} onResult={handleSTTResult}></FileUpload>
                  {/* 녹음 코드 변경하기. stt 부분만 할 수 있도록 */}
                  {STTresult_KR !== "" ? <h4>{STTresult_KR}</h4> : <></>}
                  {/* 음성인식 결과 출력 */}
                </div>
              </STTSForm>
            )}
          {lan !== "EN" &&
            active1 === false && ( // 한국어
              <STTSForm>
                <div className="wpWrap">
                  <h3>실시간 음성인식을 제공합니다.</h3>
                  <Streaming lan={lan} onResult={handleStreamingResult} />
                  {/* 녹음 코드 변경하기. stt 부분만 할 수 있도록 */}
                  {StreamingResult_KR !== "" ? (
                    <h4>{StreamingResult_KR}</h4>
                  ) : (
                    <></>
                  )}
                  {/* 음성인식 결과 출력 */}
                </div>
              </STTSForm>
            )}
          {lan !== "EN" &&
            active2 === false && ( // 한국어
              <STTSForm>
                <div className="wpWrap">
                  <h3>서비스 준비 중 입니다.</h3>
                </div>
              </STTSForm>
            )}
        </div>
      </STTSConiainer>
      <Footer />
    </ThemeProvider>
  );
}

const STTSConiainer = styled(Container)`
  width: 1180px;
  margin-top: 10%;
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #1b2654;
    margin-bottom: 50px;
  }
  @media ${() => theme.device.mobile} {
    width: 100%;
    margin-top: 30%;
    h1 {
      font-size: 25px;
    }
  }
`;

const STTSForm = styled(Box)`
  border-radius: 4px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  margin-top: 5px;
  margin-bottom: 100px;
  width: 880px;
  h3 {
    font-size: 25px;
    color: #1573ff;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: left;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    color: #1b2654;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  textarea {
    width: 770px !important;
    height: 120px !important;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 30px;
    resize: none;
    outline: none;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;
  }
  .wpWrap {
    padding: 50px;
    .btn-relation {
      flex-direction: row;
    }
  }
  .resultBox {
    background-color: #e8eaf2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 780px;
    min-height: 230px;
    margin: 30px 0;
    padding: 10px 0;
    h2 {
      font-size: 30px;
      color: #1b2654;
      font-weight: bold;
    }
  }
  .btnWrap {
    .option {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .h-fill {
    height: fit-content !important;
    min-height: 100%;
  }
  @media ${() => theme.device.mobile} {
    width: 350px;
    textarea {
      width: 100% !important;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 15px;
    }
    .resultBox {
      width: 100%;
    }
    .wpWrap {
      padding: 30px;
    }
  }
`;

const RSBtn = styled(Button)`
  border-radius: 10px !important;
  box-shadow: none !important;
  padding: 3px 13px !important;
  margin-right: 5px;
  font-size: 16px;
  &:first-of-type {
    margin-right: 5px;
  }
`;

const StyledFormControl = styled(FormControl)`
  .MuiSelect-select {
    padding: 5px 10px;
    font-size: 14px;
  }
`;
const StyledSelect = styled(Select)`
  li {
    font-size: 14px !important;
    text-align: center;
  }
`;

export default STTS;
