import React, { useState } from "react";
import styled from "@emotion/styled";
import Nav from "../component/common/Nav";
import Footer from "../component/common/Footer";
import { Button, Container, Box, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RecordWeb from "../component/RecordWeb";
import { createTheme, ThemeProvider } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import ReactHtmlParser from "html-react-parser";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import axios from "axios";
import { donut, bar } from "../component/Graph";
import theme from "../style/theme";
import FileUpload from "../component/FileUpload";

/*
시나리오
1. 사용자는 enter로 행을 바꾸며 입력을 한다.
2. 사용자는 사용자는 , . ? ! 등 문자를 입력할 것이다. -> 공백 치환이 필요할 것으로 보인다.
   공백이 여러개인 경우 이경우에도 하나로 치환하자
3. 사용자는 문장을 입력 후 한번에 유창성을 한다.
*/
function Sori() {
  const [inputText, setInputText] = useState("");
  const [evaluation, setEval] = useState(false);
  const [audioUrl, setAudioUrl] = useState(false);
  const [activeEval, setActive] = useState(false);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(true);
  const theme = createTheme({
    typography: {
      fontFamily: "Pretendard",
    },
  });
  const onResult = (result, audioUrl) => {
    if (result.error) {
    } else {
      setActive(true);
      setEval(result);
      console.log(audioUrl);
      setAudioUrl(audioUrl);
    }
  };

  const sound = () => {
    // 유저 사운드
    console.log(audioUrl);
    const file = audioUrl;
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audio.play();
  };

  const speed = (speed) => {
    if (speed >= 3.0) {
      return "잘하고 있어요.";
    } else {
      return "보통의 원어민에 비해 조금 느린 것 같네요.";
    }
  };
  const handleClick1 = (event) => {
    setActive1(!active1);
    setActive2(!active2);
  };
  const handleClick2 = (event) => {
    setActive2(!active2);
    setActive1(!active1);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Nav />
        <WebEvalConiainer>
          <h1 style={{ fontSize: "30px" }}>영어 소리 테스트</h1>
          <div>
            <div
              style={{
                marginBottom: "10px",
              }}>
              <RSBtn
                variant="outlined"
                style={{
                  color: active1 ? "#1B2654" : "#fff",
                  backgroundColor: active1 ? "#fff" : "#1573FF",
                }}
                onClick={handleClick1}>
                녹음하기
              </RSBtn>
              <RSBtn
                variant="outlined"
                style={{
                  color: active2 ? "#1B2654" : "#fff",
                  backgroundColor: active2 ? "#fff" : "#1573FF",
                }}
                onClick={handleClick2}>
                파일등록
              </RSBtn>
            </div>
            <WebPresentationForm>
              <div className="wpWrap">
                <h3>발화문장을 입력해주세요.</h3>
                {active2 ? (
                  <h4>발화 문장을 입력 후 녹음 버튼을 눌러주세요.</h4>
                ) : (
                  <h4>발화 문장을 입력 후 파일 등록을 해주세요.</h4>
                )}
                <textarea
                  value={inputText}
                  onChange={(e) => {
                    setInputText(e.target.value);
                  }}
                  col={5}
                  maxLength={200}
                  placeholder="최대 200자까지 입력 가능합니다."></textarea>
                {inputText.length > 0 && (
                  <>
                    <div style={{ textAlign: "right" }}>
                      <Clear
                        variant="outlined"
                        onClick={() => {
                          setInputText("");
                        }}>
                        초기화
                      </Clear>
                    </div>
                    <div className="center">
                      {active2 ? (
                        <RecordWeb
                          text={inputText}
                          index={0}
                          evaluation={2}
                          onResult={onResult}>
                          녹음
                        </RecordWeb>
                      ) : (
                        <FileUpload
                          type="sori"
                          text={inputText}
                          onResult={onResult}></FileUpload>
                      )}
                    </div>
                  </>
                )}
                {activeEval && (
                  <EvalForm>
                    <h3>문장별 평가 Report</h3>
                    <div className="result-box">
                      <div className="script-sebu1">
                        {"정답스크립트: " + evaluation.script}
                      </div>
                      <div className="btn-script2">
                        <div className="script-sebu2">
                          <div>
                            {"유저스크립트: "}
                            {ReactHtmlParser(evaluation.compare)}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "left" }}>
                        <SoundButton
                          variant="outlined"
                          startIcon={<VolumeUpIcon />}
                          onClick={() => sound()}>
                          내 음성
                        </SoundButton>
                      </div>
                      <div className="threeScore">
                        <PronTitle>단어별 발음 점수</PronTitle>
                        <div className="evalThree">
                          {ReactHtmlParser(evaluation.wordScore)}.
                        </div>
                        <div class="flex-score">
                          <PronTitle>연음</PronTitle>
                          <p style={{ marginLeft: "5px" }}>
                            {evaluation.pauseScore.toFixed(2)}
                          </p>
                        </div>
                        <div className="evalThree">
                          {ReactHtmlParser(evaluation.longPause)}.
                        </div>
                        <div class="flex-score">
                          <PronTitle>강세 / 리듬</PronTitle>
                          <p style={{ marginLeft: "5px" }}>
                            {evaluation.stressScore.toFixed(2)}
                          </p>
                        </div>
                        <div className="evalThree">
                          <div>{ReactHtmlParser(evaluation.stress)}.</div>
                        </div>
                        <div class="flex-score">
                          <PronTitle>호흡</PronTitle>
                          <p style={{ marginLeft: "5px" }}>
                            {evaluation.speed.toFixed(2)}
                          </p>
                        </div>
                        <div className="evalThree">
                          <div>"{speed(evaluation.speed)}"</div>
                        </div>
                      </div>
                    </div>
                  </EvalForm>
                )}
              </div>
            </WebPresentationForm>
          </div>
        </WebEvalConiainer>

        <Footer />
      </ThemeProvider>
    </>
  );
}
const WebEvalConiainer = styled(Container)`
  width: 1180px;
  padding: 150px 0 100px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    font-weight: bold;
    text-align: center;
    color: #1b2654;
    margin-bottom: 50px;
  }
  @media ${() => theme.device.mobile1} {
    width: 100%;
    h1 {
      font-size: 25px;
    }
  }
`;

const WebPresentationForm = styled(Box)`
  border-radius: 4px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 880px;
  h3 {
    font-size: 25px;
    color: #1573ff;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
  }
  h4 {
    font-size: 15px;
    color: #1573ff;
    opacity: 70%;
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 20px;
    text-align: left;
  }
  textarea {
    width: 770px !important;
    height: 120px !important;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 30px;
    resize: none;
    outline: none;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .wpWrap {
    padding: 50px;
  }

  @media ${() => theme.device.mobile} {
    width: 600px;
    textarea {
      width: 100% !important;
      height: 250px !important;
    }
    h3 {
      font-size: 20px;
    }
    .resultBox {
      width: 100%;
    }
    .wpWrap {
      padding: 30px;
    }
  }
  @media ${() => theme.device.mobile2} {
    width: 100%;
    textarea {
      width: 100% !important;
      height: 250px !important;
    }
    h3 {
      font-size: 20px;
    }
    .resultBox {
      width: 100%;
    }
    .wpWrap {
      padding: 30px;
    }
  }
`;

const SoundButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin-top: 10px;
  color: #1573ff;
  border-color: #1573ff;
  font-weight: bold;
  @media ${() => theme.device.mobile} {
    width: content-fit;
    margin-left: 0;
  }
`;

const Clear = styled(Button)`
  width: 100px;
  height: 30px;
  color: #1573ff;
  border-color: #1573ff;
  font-weight: bold;
  @media ${() => theme.device.mobile} {
    width: content-fit;
    margin-left: 0;
  }
`;

const EvalForm = styled(Box)`
  margin-top: 20px;
  .result-box {
    padding: 10px;
    border: 1px solid;
    font-size: 20px;
  }
  .script-sebu2 {
    display: flex;
    margin-top: 20px;
  }
  .btn-script2 {
    display: flex;
  }
  .evalThree {
    margin-bottom: 10px;
  }
  .flex-score {
    display: flex;
    align-items: center;
  }
  @media ${() => theme.device.mobile} {
    width: 100%;
    .result-box {
      font-size: 18px;
    }
    .script-sebu2 {
    }
    .btn-script2 {
      display: flex;
      flex-direction: column;
    }
  }
`;
const PronTitle = styled(Box)`
  width: fit-content;
  color: #1b2654;
  font-size: 22px;
  border-radius: 20px;
  padding: 5px 10px;
  margin-bottom: 5px;
  color: #000000;
  border: 1px solid #000000;
  line-height: 1;
  text-align: left;
  margin-top: 5px;
  @media ${() => theme.device.mobile} {
    margin-left: 0px;
    margin-top: 20px;
    font-size: 20px;
  }
`;

const RSBtn = styled(Button)`
  border-radius: 10px !important;
  box-shadow: none !important;
  padding: 3px 13px !important;
  font-size: 16px;
  &:first-of-type {
    margin-right: 5px;
  }
`;

export default Sori;
