import React, { useState, useEffect } from "react";
import theme from "../style/theme";
import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";

function float32ToInt16(float32Array) {
  const int16Array = new Int16Array(float32Array.length);
  for (let i = 0; i < float32Array.length; i++) {
    const normalizedValue = Math.max(-1, Math.min(1, float32Array[i]));
    int16Array[i] = normalizedValue * 0x7fff;
  }
  return int16Array;
}

const AudioRecord = ({ lan, onResult }) => {
  const [analyser, setAnalyser] = useState();
  const [stream, setStream] = useState();
  const [media, setMedia] = useState();
  const [recording, setRecording] = useState(false);
  const [source, setSource] = useState();
  const [audioDataBuffer, setAudioDataBuffer] = useState([]);
  const [audioChunks, setAudioChunks] = useState([]);
  const [recordedAudioUrl, setRecordedAudioUrl] = useState("");
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [socket, setSocket] = useState(null);
  const [sttScript, setSttScript] = useState("");

  const BUFFER_THRESHOLD = 4800; // 버퍼에 쌓일 데이터 길이

  useEffect(() => {
    if (recording && audioDataBuffer.length >= BUFFER_THRESHOLD) {
      sendDataToServer();
      setAudioDataBuffer([]);
    }
  }, [audioDataBuffer, recording]);

  useEffect(() => {
    if (!recording && audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
      const audioUrl = URL.createObjectURL(audioBlob);
      setRecordedAudioUrl(audioUrl);

      const audio = new Audio(audioUrl);
      audio.loop = false;
      audio.volume = 1;
      setAudioPlayer(audio);
    }
  }, [audioChunks, recording]);

  const startRecording = () => {
    initWebSocket(lan); // 서버 연결
    setAudioChunks([]);
    setAudioDataBuffer([]);
    // 초기화 완료
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioCtx.createScriptProcessor(0, 1, 1);
    setAnalyser(analyser);

    function makeSound(stream) {
      const source = audioCtx.createMediaStreamSource(stream);
      setSource(source);
      source.connect(analyser);
      analyser.connect(audioCtx.destination);
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start();
        setStream(stream);
        setMedia(mediaRecorder);
        makeSound(stream);

        analyser.onaudioprocess = function (e) {
          const audioBuffer = e.inputBuffer.getChannelData(0);
          setAudioDataBuffer((prevBuffer) => [...prevBuffer, ...audioBuffer]);
          setAudioChunks((prevBuffer) => [...prevBuffer, ...audioBuffer]);
          if (audioDataBuffer.length >= BUFFER_THRESHOLD) {
            sendDataToServer();
            setAudioDataBuffer([]);
          }
        };
      });

    setRecording(true);
  };

  const stopRecording = () => {
    stream.getAudioTracks().forEach(function (track) {
      track.stop();
    });

    media.stop();

    analyser.disconnect();
    source.disconnect();

    setRecording(false);
    const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
    setAudioChunks((prevChunks) => [...prevChunks, audioBlob]);

    if (socket) {
      endSendDataToServer();
    }
  };

  const playRecordedAudio = () => {
    if (audioPlayer) {
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const audioSource = audioCtx.createBufferSource();
      const audioBuffer = audioCtx.createBuffer(
        1,
        audioChunks.length,
        audioCtx.sampleRate
      );
      const channelData = audioBuffer.getChannelData(0);

      for (let i = 0; i < audioChunks.length; i++) {
        channelData[i] = audioChunks[i];
      }

      audioSource.buffer = audioBuffer;
      audioSource.connect(audioCtx.destination);
      audioSource.start();
    }
  };

  const initWebSocket = (lan) => {
    if (lan === "EN") {
      // 서버 연결
      const ws = new WebSocket("wss://www.soundustry.co.kr/streaming/EN");
      ws.onopen = () => {
        setSocket(ws);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    } else {
      //const ws = new WebSocket("wss://www.soundustry.co.kr/streaming/KR");
      const ws = new WebSocket("wss://www.soundustry.co.kr/streaming/KR");
      ws.onopen = () => {
        setSocket(ws);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    }
  };

  const sendDataToServer = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      // float32 데이터를 int16로 변환
      const int16Array = float32ToInt16(audioDataBuffer);
      socket.send(int16Array);
      socket.onmessage = (event) => {
        const receivedData = event.data; // 서버로부터 받은 데이터
        // 스크립트 에서 # 태그 빼기
        onResult(receivedData);
      };
    }
  };

  const endSendDataToServer = () => {
    socket.send("end");
    socket.onmessage = (event) => {
      const receivedData = event.data; // 서버로부터 받은 데이터
      console.log(receivedData); // 상태 업데이트
      onResult(receivedData);
    };
  };
  return (
    <>
      <StyledBtn
        variant="outlined"
        startIcon={<KeyboardVoiceIcon />}
        onClick={recording ? stopRecording : startRecording}>
        {recording ? "녹음 중지" : "녹음 시작"}
      </StyledBtn>
      {/*}
      <button onClick={playRecordedAudio} disabled={recording}>
        녹음 듣기
      </button>
  */}
    </>
  );
};

const StyledBtn = styled(Button)`
  width: fit-content;
  padding: 5px 20px;
  color: #1573ff;
  border-color: #1573ff;
  font-weight: bold;
  margin-right: 10px;
  @media ${() => theme.device.mobile} {
    width: 100%;
  }
`;

export default AudioRecord;
