/*파일 업로드 버튼 */
/*파일 업로드의 경우 파일 버튼 , 파일 명칭을 보고 유창성 뜨게 하기. */
import React, { useState } from "react";
import { Button, Container } from "@mui/material";
import styled from "@emotion/styled";
import theme from "../style/theme";
function FileUpload({ lan, onResult }) {
  const handleFileSelect = () => {
    const fileInput = document.getElementById("fileInput");
    // 파일 선택 다이얼로그 열기
    fileInput.click();
  };

  // 파일 선택이 변경되었을 때 실행되는 함수
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile);
    if (
      selectedFile &&
      window.confirm(selectedFile.name + "으로 소리테스트 하시겠습니까?")
    ) {
      if (lan === "EN" && selectedFile.name.includes(".mp3")) {
        const formData = new FormData();
        formData.append("blob", selectedFile);
        //fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/stt/EN/mp3`, {
        fetch(`/api/stt/EN/mp3`, {
          method: "POST",
          body: formData,
          headers: {},
        })
          .then((response) => {
            if (!response.ok) {
              alert("다른 음성 파일을 등록해주세요.");
            } else {
              return response.json();
            }
          })
          .then((result) => {
            onResult(result["script"]);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (lan === "EN" && selectedFile.name.includes(".wav")) {
        const formData = new FormData();
        formData.append("blob", selectedFile);
        //fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/stt/EN/wav`, {
        fetch(`/api/stt/EN/wav`, {
          method: "POST",
          body: formData,
          headers: {},
        })
          .then((response) => {
            if (!response.ok) {
              alert("다른 음성 파일을 등록해주세요.");
            } else {
              return response.json();
            }
          })
          .then((result) => {
            onResult(result["script"]);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (lan === "KR" && selectedFile.name.includes(".mp3")) {
        const formData = new FormData();
        formData.append("blob", selectedFile);
        //fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/stt/KR/mp3`, {
        fetch(`/api/stt/KR/mp3`, {
          method: "POST",
          body: formData,
          headers: {},
        })
          .then((response) => {
            if (!response.ok) {
              alert("다른 음성 파일을 등록해주세요.");
            } else {
              return response.json();
            }
          })
          .then((result) => {
            onResult(result["script"]);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (lan === "KR" && selectedFile.name.includes(".wav")) {
        const formData = new FormData();
        formData.append("blob", selectedFile);
        //fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/stt/KR/wav`, {
        fetch(`/api/stt/KR/wav`, {
          method: "POST",
          body: formData,
          headers: {},
        })
          .then((response) => {
            if (!response.ok) {
              alert("다른 음성 파일을 등록해주세요.");
            } else {
              return response.json();
            }
          })
          .then((result) => {
            onResult(result["script"]);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    // 파일 선택이 완료되면 input 요소 초기화
    e.target.value = null;
  };

  return (
    <>
      <label htmlFor="file" onClick={handleFileSelect}>
        <StyledBtn variant="outlined">파일 업로드</StyledBtn>
      </label>
      <input
        type="file"
        id="fileInput"
        accept=".mp3,.wav"
        style={{ display: "none" }}
        onChange={handleFileChange} // 파일 선택이 변경될 때 실행될 함수
      />
    </>
  );
}

const StyledBtn = styled(Button)`
  width: fit-content;
  padding: 5px 20px;
  color: #1573ff;
  border-color: #1573ff;
  font-weight: bold;
  margin-right: 10px;
  @media ${() => theme.device.mobile} {
    width: 100%;
  }
`;

export default FileUpload;
